import { Component, Input } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { SeoService } from 'src/app/services/seo.service';
import { FooterComponent } from 'src/app/templates/footers/footer-switcher.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
@Component({
    selector: 'fc-iselect-footer',
    templateUrl: './iselect-footer.component.html',
    styleUrls: ['iselect-footer.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective]
})
export class IselectFooterComponent implements FooterComponent {
  constructor(public seoService: SeoService) { }
  @Input() links: SectorStaticFooterDto[];

}
