<div class="ist-header-grid">
    <div class="ist-header">
        <div class="ist-mobile-btn" (click)="onToggleMobile()">
            <i class="ist-icon ist-burger"></i>
        </div>
        <a class="ist-logo" href="https://www.iselect.com.au">
            <img width="88px" height="40px" src="https://ik.imagekit.io/fairaus/iselect/iselect-logo.svg"
                loading="eager" alt="Compare insurance  companies in Australia with iSelect">
        </a>
        <div class="ist-main-menu">
            <ul>
                <li *ngFor="let m of menu" (mouseenter)="onMouseEnter(m)" (mouseleave)="onMouseLeave(m)">
                    <label *ngIf="!m.href" (click)="onClick(m)" [innerHtml]="m.title"></label>
                    <a *ngIf="m.href" [href]="m.href" [innerHtml]="m.title"></a>
                    <div class="is-second-menu"  *ngIf="showMenu(m)" [ngClass]='{ "hover": m.isHover}'>
                        
                        <ul class="fc-lg-level-two" >
                            <li *ngFor="let c of m.children; let i = index" [ngClass]="{'active': isActive(c)}">
                                <span *ngIf="c.route">
                                    <a [FCRouterLink]="c.route" [innerHtml]="c.title" [id]="'fc-top-menu-' + i"></a>
                                </span>
                                <span *ngIf="c.href"><a [href]="c.href" [innerHtml]="c.title"></a></span>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="ist-fc-logo">
            <label>This comparison <br />service is powered by</label>
            <a href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="eager"
                    src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
                    alt="Fair Comparison - white label comparison technology provider"></a>
        </div>
    </div>
</div>
<div class="ist-breadcrumb">
    <fc-default-breadcrumb [showOnRoot]="true" [showExternal]="true" [showPreviousOnly]="false" [showLowest]="true"></fc-default-breadcrumb>
</div>
<div class="ist-mobile-menu" [ngClass]="{'menu-show': showMobile}"> 
    <ul class="ist-mobile-first">
        <li *ngFor="let m of menu">
            <section *ngIf="!m.children"><a [href]="m.href">{{m.title}}</a></section>
            <section class="ist-second-menu ist-right-arrow" *ngIf="m.children" (click)="onToggleAccodian($event)">{{m.title}}</section>
            <div class="ist-mobile-second">
                <ul>
                    <li *ngFor="let c of m.children; let i = index;">
                        <a class="ist-right-arrow" *ngIf="c.route" [FCRouterLink]="c.route" [innerHtml]="c.title" (click)="onToggleMobile()" [id]="'fc-mobile-menu-' + i"></a>
                        <a class="ist-right-arrow" *ngIf="c.href"  [href]="c.href" [innerHtml]="c.title"></a>
                    </li>
                </ul>
            </div>
        </li>
    </ul> 
</div>
    


<!--
<div class="iselect-sticky-header" >
    <div class="fc-layout-top-header-content">
        <div class="iselect-desktop-header">
            <div class="iselect-desktop-logo">
               
            </div>
            <div class="iselect-desktop-menu" >
                <ul class="fc-lg-level-one">
                    <li *ngFor="let m of menu" (mouseenter)="onMouseEnter(m)" (mouseleave)="onMouseLeave(m)">
                        <span *ngIf="!m.href" (click)="onClick(m)" [innerHtml]="m.title">{{m.title}}</span>
                        <span *ngIf="m.href"><a [href]="m.href" [innerHtml]="m.title"></a></span>
                        <div>
                            <ul class="fc-lg-level-two" *ngIf="showMenu(m)" [ngClass]='{ "hover": m.isHover}'>
                                <li *ngFor="let c of m.children; let i = index" [ngClass]="{'active': isActive(c)}">
                                    <span *ngIf="c.route">
                                        <a [FCRouterLink]="c.route" [innerHtml]="c.title" [id]="'fc-top-menu-' + i"></a>
                                    </span>
                                    <span *ngIf="c.href"><a [href]="c.href" [innerHtml]="c.title"></a></span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="iselect-desktop-fc-logo">
                <div class="iselect-fc-text">This comparison <br />service is powered by</div>
                <div class="iselect-fc-logo">
                    <a href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="eager"
                            src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
                            alt="Fair Comparison - white label comparison technology provider"></a>
                </div>
            </div>
        </div>

        <div class="iselect-mobile-header">
            <div class="iselect-mobile-burger">
                <div class="burger" (click)="onToggleMobile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19" *ngIf="!showMobile">
                        <g fill="#737373" transform="translate(0 -3)">
                            <path
                                d="M1.25 13.75C.559644063 13.75 0 13.1903559 0 12.5 0 11.8096441.559644063 11.25 1.25 11.25L23.75 11.25C24.4403559 11.25 25 11.8096441 25 12.5 25 13.1903559 24.4403559 13.75 23.75 13.75L1.25 13.75zM1.25 6.25C.559644063 6.25 0 5.69035594 0 5 0 4.30964406.559644063 3.75 1.25 3.75L23.75 3.75C24.4403559 3.75 25 4.30964406 25 5 25 5.69035594 24.4403559 6.25 23.75 6.25L1.25 6.25zM1.25 21.25C.559644063 21.25 0 20.6903559 0 20 0 19.3096441.559644063 18.75 1.25 18.75L23.75 18.75C24.4403559 18.75 25 19.3096441 25 20 25 20.6903559 24.4403559 21.25 23.75 21.25L1.25 21.25z">
                            </path>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" *ngIf="showMobile">
                        <g fill="#737373" transform="translate(-2 -2)">
                            <path
                                d="M20.061276,3.00630951 C20.6191679,2.44841766 21.5236893,2.44841766 22.0815811,3.00630951 C22.639473,3.56420135 22.639473,4.46872275 22.0815811,5.0266146 L4.93872397,22.1694717 C4.38083213,22.7273636 3.47631073,22.7273636 2.91841888,22.1694717 C2.36052704,21.6115799 2.36052704,20.7070585 2.91841888,20.1491667 L20.061276,3.00630951 Z" />
                            <path
                                d="M2.91841888,5.0266146 C2.36052704,4.46872275 2.36052704,3.56420135 2.91841888,3.00630951 C3.47631073,2.44841766 4.38083213,2.44841766 4.93872397,3.00630951 L22.0815811,20.1491667 C22.639473,20.7070585 22.639473,21.6115799 22.0815811,22.1694717 C21.5236893,22.7273636 20.6191679,22.7273636 20.061276,22.1694717 L2.91841888,5.0266146 Z" />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="iselect-mobile-icons">
                <div class="iselect-mobile-logo">
                    <a href="https://www.iselect.com.au"><img width="88px" height="40px" loading="lazy"
                            src="https://www.iselect.com.au/content/themes/iselect/assets/images/menu-nav/logo-new.svg"
                            alt="Compare insurance companies in Australia with iSelect"></a>
                </div>
                <div class="iselect-mobile-fc-text">
                    This comparison <br />service is powered by
                </div>
                <div class="iselect-mobile-fc-logo">
                    <a href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="30" loading="lazy"
                            src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
                            alt="Fair Comparison - white label comparison technology provider"></a>
                </div>
            </div>
        </div>         
    </div>
</div>
 -->

