import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationRootDto } from 'src/app/models/generated/navigation/NavigationRootDto';
import { AccordianBaseComponent } from 'src/app/shared/accordian-base-compontent';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgFor, NgIf, NgClass } from '@angular/common';

interface ISelectChild {
  title: string;
  href?: string;
  route?: string;
}

interface ISelectMenu {
  title: string;
  isExpanded: boolean;
  isActive: boolean;
  isHover: boolean;
  isPinned: boolean;
  href?: string;
  children?: ISelectChild[];
}

@Component({
    selector: 'fc-iselect-top-header',
    templateUrl: './iselect-top-header.component.html',
    styleUrls: ['./iselect-top-header.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        NgClass,
        FCRouterLinkDirective,
        DefaultBreadcrumbComponent,
    ],
})
export class IselectTopHeaderComponent implements HeaderComponent, OnInit {
  root: NavigationRootDto | null | undefined;
  scrollTop = 0;
  menu: ISelectMenu[] = [];
  hoverMenu: ISelectMenu;
  pinnedMenu: ISelectMenu;
  showMobile = false;

  accodianHelper: AccordianBaseComponent;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.accodianHelper = new AccordianBaseComponent('ist-mobile-second', 'ist-second-menu-expanded');

    const baseRoute = '/compare/';
    const baseUrl = 'https://www.iselect.com.au';
    this.menu = [
      {
        title: 'Insurance', isExpanded: false, isHover: false, isActive: false, isPinned: false,
        children: [
          { title: 'Health', href: `${baseUrl}/health-insurance/` },
          { title: 'Life', href: `${baseUrl}/life/` },
          { title: 'Car', href: `${baseUrl}/car-insurance/` },
          { title: 'Home & Contents', href: `${baseUrl}/home-and-contents/` },
          { title: 'Business Insurance', href: `${baseUrl}/business-insurance/` },
          { title: 'Travel', href: `${baseUrl}/travel-insurance/` },
          { title: 'Pet', href: `${baseUrl}/pet-insurance/` },
          { title: 'Overseas Visitors Cover ', href: `${baseUrl}/overseas-visitors-health-cover/` }
        ]
      },
      {
        title: 'Utilities', isExpanded: false, isHover: false, isActive: false, isPinned: false,
        children: [
          { title: 'Electricity &amp; Gas', href: `${baseUrl}/energy/` },
          { title: 'Internet', href: `${baseUrl}/internet/` },
          { title: 'Mobile Phones &amp; Plans', href: `${baseUrl}/mobile/` }
        ]
      },
      {
        title: 'Finance', isExpanded: false,  isHover: false, isActive: true, isPinned: false,
        children: [
          { title: 'Home Loans', href: `${baseUrl}/home-loans/` },
          { title: 'Personal Loans', route: `${baseRoute}personal-loans` },
          { title: 'Car Loans', route: `${baseRoute}car-loans` },
          { title: 'Business Loans', href: `${baseUrl}/business-loans/` },
          { title: 'Credit Cards', route: `${baseRoute}credit-cards` },
          { title: 'Income Protection', href: `${baseUrl}/life/income-protection-insurance/` }
        ]
      }, {
        title: 'Moving House?', isExpanded: false, isHover: false, isActive: false, isPinned: false, href: `${baseUrl}/moving-house/`
      }, {
        title: 'Blog', isExpanded: false, isHover: false, isActive: false, isPinned: false, href: `${baseUrl}/blog/`
      }
    ];
  }

  isActive(route: ISelectChild): boolean {
    if (route.route) {
     return route.route.indexOf(this.router.url) > -1;
    }
    return false;
  }
  onToggleAccodian(event: any) {
    this.accodianHelper.toggleAccordian(event);
  }
  onToggleMobile(): void {
    if (this.showMobile) {
      this.showMobile = false;

      this.menu.forEach(x => {
        x.isExpanded = false;
      });
    }
    else {
      this.showMobile = true;
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any): void {
    this.scrollTop = event.target.documentElement.scrollTop;
  }

  onClick(menu: ISelectMenu): void {
    if (this.pinnedMenu) {
      this.pinnedMenu.isPinned = false;
    }

    menu.isPinned = true;
    this.pinnedMenu = menu;
  }

  onMouseEnter(menu: ISelectMenu): void {
    menu.isHover = true;

    if (this.hoverMenu) {
      this.hoverMenu.isHover = false;
    }

    if (this.pinnedMenu && this.pinnedMenu != menu) {
      this.pinnedMenu.isPinned = false;
    }

    this.hoverMenu = menu;
  }

  onMouseLeave(menu: ISelectMenu): void {
    menu.isHover = false;
    if (this.hoverMenu) {
      this.hoverMenu.isHover = false;
    }
  }

  showMenu(menu: ISelectMenu): boolean | undefined {
    return menu.children &&
      ( menu.isActive || menu.isHover ||  menu.isPinned) &&
      ( this.scrollTop < 100  || menu.isPinned || menu.isHover );
  }
}
