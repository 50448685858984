<ul class="ist-partner-links">
    <li>About iSelect</li>
    <li><a href="https://www.iselect.com.au/content/uploads/2023/11/iSelect-Services-Guide-.pdf" target="_blank">iSelect Services Guide</a></li>
    <li><a href="https://www.iselect.com.au/contact-us/" target="_blank" >Contact us</a></li>
    <li><a href="https://www.iselect.com.au/how-iselect-works/" target="_blank" >How iSelect Works</a></li>
    <li><a href="https://www.iselect.com.au/corporate/our-company/" target="_blank" >Our Company </a></li>
    <li><a href="https://www.iselect.com.au/corporate/careers/" target="_blank" >Careers</a></li>
    <li><a href="https://www.iselect.com.au/corporate/media-centre/" target="_blank" >Media Centre</a></li>
    <li><a href="https://www.iselect.com.au/new-partner-enquiry-form/" target="_blank" >Become our Partner</a></li>
    <li><a href="https://www.iselect.com.au" target="_blank" >Sponsorships</a></li>
</ul>
<ul class="ist-fc-links">
    <li class="iselect-footer-header">Fair Comparison</li>
    <li><a [FCRouterLink]="seoService.getDisclaimerPage('terms-and-conditions')" id="fc-footer-website-terms">Website Terms & Conditions</a></li>
    <li><a [FCRouterLink]="seoService.getDisclaimerPage('services-guide')" id="fc-footer-services"> Fair Comparison Services Guide</a></li>
    <li><a [FCRouterLink]="seoService.getDisclaimerPage('privacy-policy')" id="fc-footer-privacy-policy">Privacy Policy</a></li>
</ul>
<div class="ist-icons">
    <a class="ist-icon" href="https://www.iselect.com.au"><img width="90px" height="40px" loading="lazy"
        src="https://ik.imagekit.io/fairaus/iselect/iselect-logo.svg"
            alt="Compare insurance companies in Australia with iSelect"></a>
    <div class="ist-fc-text">
        <p>This comparison <br />service is powered by</p>
    </div>
    <a class="ist-fc-icon" href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="lazy"
        src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
        alt="Fair Comparison - white label comparison technology provider"></a>
</div>
<div class="ist-disclaimer">Tyrian Pty Ltd - ACN: 158 954 655</div>